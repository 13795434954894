import { cropImage } from "@brightsites/flow-core/lib/utils/cropImage";

export type CropObject = {
  name: keyof typeof cropMap;
  trimData: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  cropData: {
    x: number;
    y: number;
    width: number;
    height: number;
    rotate: number;
    scaleX: number;
    scaleY: number;
  };
};

export const cropMap = {
  Freeform: "Freeform",
  onebyone: "1:1",
  fivebyeight: "5:8",
  fourbyfive: "4:5",
  threebytwo: "3:2",
  eightbyfive: "8:5",
  fivebythree: "5:3",
  twobyone: "2:1",
};

const calculateHeight = (
  cropName: keyof typeof cropMap,
  width: number,
  originalWidth: number,
  originalHeight: number,
): number => {
  const ratio = cropMap[cropName];

  if (ratio === "Freeform") {
    // Preserve the original aspect ratio of the image
    const aspectRatio = originalHeight / originalWidth;
    return Math.round(width * aspectRatio);
  }

  const [widthRatio, heightRatio] = ratio.split(":").map(Number);
  return Math.round((width * heightRatio) / widthRatio);
};

const generateImageURL = (
  image: string,
  cropData: CropObject,
  mobile?: boolean,
): string => {
  try {
    const baseWidth = mobile ? 455 : 752;
    const height = calculateHeight(
      cropData.name,
      baseWidth,
      cropData.cropData.width,
      cropData.cropData.height,
    );

    return `${image}?${cropImage(
      cropData,
    )}width=${baseWidth}&height=${height}&crop=${baseWidth}:${height}`;
  } catch (error) {
    console.error("Error generating image URL:", error);

    // Fallback to static dimensions
    if (mobile) {
      return `${image}?${cropImage(cropData)}width=455&height=350&crop=455:350`;
    } else {
      return `${image}?${cropImage(cropData)}width=752&height=500&crop=752:500`;
    }
  }
};

export const imageHeroUrl = (
  publication: string,
  image: string,
  cropData?: CropObject,
  mobile?: boolean,
  size?: number,
) => {
  try {
    if (size === 1) {
      return `${image}?${cropImage(
        cropData,
      )}width=950&height=6450&crop=950:645`;
    }

    if (!cropData) {
      // Fallback logic if cropData is not provided
      return mobile
        ? `${image}?width=455&height=350&crop=455:350`
        : `${image}?width=752&height=500&crop=752:500`;
    }

    return generateImageURL(image, cropData, mobile);
  } catch {
    return image;
  }
};

export const imageHeroUrlPortrait = (
  publication: string,
  image: string,
  cropData: object = {},
  mobile?: boolean,
  size?: number,
) => {
  try {
    if (size === 1) {
      return `${image}?width=950`;
    }
    if (mobile) {
      return `${image}?width=455`;
    } else {
      return `${image}?width=669`;
    }
  } catch {
    return image;
  }
};

export const imageItemUrl = (
  publication: string,
  image: string,
  size: number,
  mpu?: boolean,
  role?: string,
  mobile?: boolean,
) => {
  try {
    let width = size === 3 ? 209 : 119;
    let height = size === 3 ? 140 : 79;
    let crop = size === 3 ? "&crop=209:145,smart" : "&crop=119:79,smart";
    let itemx2 = `${image}?width=209&height=145&crop=240:145,smart&quality=75`;
    let itemx2Mobile = `${image}?width=96&height=64&crop=64:96,smart&quality=75`;
    let itemx3_plus_MPU = `${image}?width=209&height=145&crop=240:145,smart&quality=75`;
    let itemx3 = `${image}?width=${width}&height=${height}${crop}&quality=75`;

    if (mobile === true) {
      return itemx2Mobile;
    }
    if (role === "job") {
      return image;
    }
    if (size === 2 && mobile === false) {
      return itemx2;
    }

    return mpu ? itemx3_plus_MPU : itemx3;
  } catch {
    return image;
  }
};
