import styled, {css, keyframes} from "styled-components";
import { AiOutlineSearch } from "react-icons/ai";
import {defaultBreakpoints} from "styled-media-query";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
`;

export const SearchIcon = styled(AiOutlineSearch)`
margin: auto;
font-size: 1.8rem;
color:  #606163;
fill:  #606163;
`;

export const SearchButton = styled.button`
  background: white;
  border: none;
  font-size: 1.8rem;
  margin: auto 0 auto 10px;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;  
  }  
`;



export const SearchWrapper = styled.div<{ isVisible: boolean }>`
  display: flex;
  width: 100%;
  div {
    ${({ isVisible }) => css`
    display: ${isVisible ? "block" : "none"};
    animation: ${isVisible ? fadeIn : fadeOut} 0.3s ease-in-out;
  `}
 }
    @media (max-width: ${defaultBreakpoints.medium}) {
        display: none;
    }   
`;

export const SearchWrapperMobile = styled.div`
  display: flex;
  width: 100%;
  @media (min-width: ${defaultBreakpoints.medium}) {
    display: none;  
  }  
`;
