import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

type StyledProps = {
  selected?: boolean;
};

export const PrimaryMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding-left: 18px;
  padding-right: 18px;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.default.color.text.dark}`};
  a {
    display: flex;
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  @media (max-width: ${defaultBreakpoints.large}) {
    display: none;
  }
`;

export const SecondaryMenuWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  padding-left: 0;
  height: 55px;
  padding: 0 52px 0 45px;
  background-color: ${({ theme }) => theme.default.color.backgrounds.offWhite};
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.default.color.text.dark}`};
  a {
    display: flex;
    align-items: center;
  }
`;

export const TertiaryMenuWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  top: 105px;
  height: 55px;
  padding-left: 5px;
  background-color: ${({ theme }) => theme.default.color.backgrounds.offWhite};
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.default.color.text.dark}`};
  a {
    display: flex;
    align-items: center;
  }
`;

export const IconsWrapper = styled.div`
  padding-right: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
`;

// route items

export const Route = styled.div`
  padding: 0 0.6rem;
  display: flex;
`;

export const StyledRouteTitle = styled.p<StyledProps>`
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: auto 0;
  text-align: center;
  vertical-align: middle;
  border-bottom: ${({ theme, selected }) =>
    selected && `2px solid ${theme.default.color.primary}`};
  color: ${({ theme, selected }) => selected && theme.default.color.primary};
  :hover {
    color: ${({ theme, selected }) => theme.default.color.primary};
  }
`;

export const StyledRouteSecondayTitle = styled.p<StyledProps>`
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 12px;
  text-transform: uppercase;
  margin: 1rem 15px;
  border-bottom: ${({ theme, selected }) =>
    selected && `2px solid ${theme.default.color.primary}`};
  color: ${({ theme, selected }) => selected && theme.default.color.primary};
  :hover {
    color: ${({ theme, selected }) => theme.default.color.primary};
  }
`;
