import { FC, useRef, useEffect } from "react";
import CollapsedMenuSection from "./CollapsedMenuSection";
import {
  StyledMenu,
  StyledSection,
  SearchBarWrapper,
  TopicWrapper,
  SubHeading,
} from "../FullMenu.styled";
import MenuSection from "./MenuSection";
import { useDevice } from "common/hooks/useDevice";
import Search from "Components/Search";
import { FullMenuProps } from "common/Layout/types/Layout.types";
import Topics from "common/Layout/Article/Topics";

interface Props {
  setFullMenuOpen: (x: boolean) => void;
  openFullMenu: boolean;
  fullMenu: FullMenuProps;
}

type Item = {
  title: string;
  path: string;
  children: Array<object>;
};

const formatArray = (fullMenu: any) => {
  let newArray: Array<any> = [];
  let CollapsedArray: Array<any> = [];
  fullMenu.menu.forEach((item: Item) => {
    if (item.children) {
      newArray.push(item);
    } else {
      CollapsedArray.push(item);
    }
  });
  newArray.splice(2, 0, CollapsedArray);
  return newArray;
};

const FullMenuBody: FC<Props> = ({
   fullMenu,
   openFullMenu,
   setFullMenuOpen,
}) => {
  const { screenSize } = useDevice();

  return (
    <StyledMenu open={openFullMenu}>
      {screenSize.medium && (
        <SearchBarWrapper>
          <Search />
        </SearchBarWrapper>
      )}
      <StyledSection>
        {formatArray(fullMenu).map((item, i) => {
          if (i === 2) {
            return <CollapsedMenuSection key={i} menuItems={item} />;
          } else {
            return (
              <MenuSection
                closeMenu={() => setFullMenuOpen(!openFullMenu)}
                key={i}
                menuItem={item}
              />
            );
          }
        })}
      </StyledSection>
      <TopicWrapper>
          <SubHeading>Popular reads</SubHeading>
          <Topics />
        </TopicWrapper>
    </StyledMenu>
  );
};

export default FullMenuBody;
