import { FC, useState } from "react";
import FullMenu from "./FullMenu";
import Logo from "Components/Logo";
import Social from "./Social";
import WeatherWidget from "Components/WeatherWidget";
import AuthComponent from "common/Auth";
import {Wrapper, InnerWrapper, PrimaryMenuSearchWrapper} from "./Topbar.styled";
import { FullMenuProps } from "common/Layout/types/Layout.types";
import { Weather } from "Components/WeatherWidget/WeatherWidget";
import { useDelayUnmount } from "common/hooks/useDelayUnmount";
import FullMenuBody from "./FullMenu/FullMenuBody";
import Search from "../../../../Components/Search";

type Props = {
  fullMenu: FullMenuProps;
  weather: Weather;
  headerNav: any;
};

const Topbar: FC<Props> = ({ fullMenu, weather, headerNav }) => {
  const [openFullMenu, setFullMenuOpen] = useState(false);
  const shouldRenderChild = useDelayUnmount(openFullMenu, 180);

  return (
    <Wrapper>
      <InnerWrapper>
        <FullMenu
          openFullMenu={openFullMenu}
          setFullMenuOpen={setFullMenuOpen}
          fullMenu={fullMenu}
        />
        <WeatherWidget weather={weather} />
      </InnerWrapper>
      <Logo />
      <PrimaryMenuSearchWrapper>
        <Search />
      </PrimaryMenuSearchWrapper>
      <Social openFullMenu={openFullMenu} />
      <AuthComponent />
      {shouldRenderChild && (
        <FullMenuBody
          fullMenu={fullMenu}
          openFullMenu={openFullMenu}
          setFullMenuOpen={setFullMenuOpen}
        />
      )}
    </Wrapper>
  );
};

export default Topbar;
