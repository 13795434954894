import { FC, useState, useEffect } from "react";
import dynamic from "next/dynamic";
import { SearchWrapper, SearchButton, SearchWrapperMobile } from "./Search.styled";
import { useRouter } from "next/dist/client/router";
import SearchIcon from "common/icons/search";
import { useDevice } from "../../common/hooks/useDevice";

const Input = dynamic(() => import("Components/Input"), { ssr: false });

const Search: FC = () => {
  const router = useRouter();
  const [value, setValue] = useState("");
  const [isInputVisible, setInputVisible] = useState(false);
  const { screenSize } = useDevice();

  useEffect(() => {
    const handleRouteChange = () => {
      setValue("");
      setInputVisible(false);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const trimmedValue = value.trim();

    // Don't do anything if the search query is empty
    if (!trimmedValue) return;

    const searchQuery = encodeURIComponent(trimmedValue);
    const currentPath = router.asPath;
    const newPath = `/search?search=${searchQuery}`;

    if (currentPath.startsWith('/search')) {
      await router.replace(newPath);
      window.location.reload();
    } else {
      await router.push(newPath);
    }
  };

  const handleButtonClick = () => {
    setInputVisible(!isInputVisible);
  };

  return (
    <form onSubmit={handleSubmit}>
      {/*mobile search*/}
      <SearchWrapperMobile>
        <Input
          handleChange={handleChange}
          value={value}
          placeholder="Search"
        />{" "}
        <SearchButton type="submit">
          <SearchIcon/>
        </SearchButton>
      </SearchWrapperMobile>
      {/*desktop search*/}
      <SearchWrapper isVisible={isInputVisible}>
        {isInputVisible && (
          <Input
            handleChange={handleChange}
            value={value}
            placeholder="Search"
          />
        )}
        <SearchButton type="button" onClick={handleButtonClick}>
          <SearchIcon />
        </SearchButton>
      </SearchWrapper>
    </form>
  );
};

export default Search;