import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Wrapper = styled.div`
    display: grid;
    grid-template-rows: 70px;
    grid-template-columns: 0.4fr 2fr 0.4fr;
    border-bottom: ${({ theme }) => `1px solid ${theme.default.color.text.dark}`};
    padding: 0 16px;

  @media (min-width: ${defaultBreakpoints.medium}) {
    display: grid;
    grid-template-rows: 85px;
    grid-template-columns: 1fr 2fr 1fr 55px;
    justify-content: space-between;
    position: relative;
  }
  
  @media (min-width: ${defaultBreakpoints.large}) {
    border-bottom: 0;
    padding: 0 52px;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justfify-content: flex-start;
`
export const PrimaryMenuSearchWrapper = styled.div`
  display: none;  
  @media (min-width: ${defaultBreakpoints.medium}) {
    margin: auto 0;
    display: flex;
    justify-content: end;
  }
  @media (min-width: ${defaultBreakpoints.large}) {
    padding-top: 10px;
  }
`;