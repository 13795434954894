import { FC, useRef, useEffect, useState } from "react";
import { Twirl as Hamburger } from "hamburger-react";
import {
  FullMenuWrapper, Placeholder,
  StyledHamburgerWrapper,
} from "./FullMenu.styled";
import { FullMenuProps } from "common/Layout/types/Layout.types";

interface Props {
  setFullMenuOpen: (x: boolean) => void;
  openFullMenu: boolean;
  fullMenu: FullMenuProps;
}

const FullMenu: FC<Props> = ({ fullMenu, openFullMenu, setFullMenuOpen }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      window.innerWidth > 1170 && // Only activate on desktop
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setFullMenuOpen(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 1170) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (

    <FullMenuWrapper ref={wrapperRef}>
      <StyledHamburgerWrapper>
        {isMounted ? (
          <Hamburger
            size={20}
            hideOutline={true}
            toggled={openFullMenu}
            toggle={() => setFullMenuOpen(!openFullMenu)}
            duration={0.2}
            direction="right"
            distance="sm"
            label="Menu"
          />
          ) : (
          <Placeholder/>
        )}
      </StyledHamburgerWrapper>
    </FullMenuWrapper>
  );
};

export default FullMenu;