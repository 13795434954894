import React from 'react';

export const IOMTodayLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" version="1.1" width="200" height="70" viewBox="0 0 255 115">

    <defs>
      <style>
        {`
          .st0 {
            fill: #231f20;
          }
          .st1 {
            fill: #33559d;
          }
        `}
      </style>
    </defs>
    <g>
      <path className="st1"
            d="M49.3,91.5h-15.6c.9-2.4,1.6-4.3,1.9-6,.4-1.7.5-3.9.5-6.7v-30l-2.9.2c-.5,0-1,.2-1.6.5-.6.3-1.2.9-1.9,1.6-.7.7-1.3,1.4-1.8,2-.5.6-1.1,1.4-2,2.6-.8,1.2-1.4,2-1.7,2.5-.3.5-.9,1.4-1.8,2.7-.9,1.3-1.4,2.1-1.4,2.2l-.7-.2,2.6-15.3h37.1l2.6,15.3-.7.2c-.1-.2-.5-.7-1.1-1.7-.6-1-1.1-1.6-1.3-2-.2-.4-.7-1-1.3-2-.6-.9-1.1-1.6-1.5-2.1-.4-.5-.8-1.1-1.4-1.8-.6-.8-1.1-1.4-1.5-1.8-.4-.4-.9-.9-1.4-1.3-.5-.5-1-.8-1.4-1-.4-.2-.8-.3-1.2-.4l-2.9-.2v30c0,2.8.2,5,.5,6.6.3,1.7,1,3.6,1.9,5.9h0Z"/>
      <path className="st1"
            d="M88.7,92.4c-6.6,0-12.1-2.2-16.6-6.5-4.5-4.4-6.7-9.8-6.7-16.3s2.2-11.9,6.7-16.3c4.5-4.4,10-6.6,16.6-6.6s12.1,2.2,16.6,6.6c4.5,4.4,6.7,9.9,6.7,16.3s-2.2,11.9-6.7,16.3c-4.5,4.4-10,6.5-16.6,6.5ZM88.7,91c4,0,6.9-1.9,8.8-5.8,1.9-3.8,2.9-9.1,2.9-15.7s-.9-11.8-2.8-15.7c-1.9-3.9-4.8-5.8-8.8-5.8s-7,1.9-8.9,5.8c-1.9,3.9-2.9,9.1-2.9,15.7s.9,11.8,2.9,15.7c1.9,3.8,4.9,5.8,8.9,5.8Z"/>
      <path className="st1"
            d="M115.6,91.4c.9-2.1,1.5-4,1.9-5.6.3-1.6.5-3.7.5-6.4v-19.7c0-2.7-.2-4.8-.5-6.4-.3-1.6-1-3.4-1.9-5.6h0c2-.1,4.7-.2,8.2-.2,3.5,0,6.7-.1,9.5-.1,8.2,0,14.6,1.9,19.2,5.7,4.7,3.8,7,9.3,7,16.3s-2.3,12.5-6.8,16.4-11,5.8-19.4,5.8h-17.7ZM133.5,90c5.4,0,9.2-1.6,11.3-4.9,2.1-3.2,3.2-8.2,3.2-14.9s-1.1-12.8-3.4-16.3-6.1-5.2-11.3-5.1c-1,0-2.5.1-4.4.3v40.6c1.8.2,3.3.2,4.7.2Z"/>
      <path className="st1"
            d="M169.3,91.5h-11c2.3-2.3,4.5-6.1,6.4-11.3l11.8-32.8h6.1l12,33.1c1.7,4.8,3.6,8.4,5.6,10.9h0c0,.1-17.9.1-17.9.1h0c2.4-3.3,2.8-7.3,1.1-12.1l-1.6-4.4h-13.7l-1.1,2.9c-2.2,6.2-1.4,10.7,2.3,13.5h0ZM175.1,55.7l-6.5,17.8h12.7l-6.2-17.8Z"/>
      <path className="st1"
            d="M207.2,91.4c.9-1.9,1.6-3.6,1.9-5,.4-1.4.5-3.3.5-5.7v-10.2l-7-12.8c-1.6-2.9-2.8-5.1-3.8-6.5-.9-1.4-2-2.5-3.2-3.5v-.2h19.1v.2c-1,.8-1.6,2.2-1.8,3.9-.2,1.8.3,3.9,1.4,6.5l5.5,10,4.7-8.5c2.6-4.7,2.1-8.7-1.6-12h0c0-.1,12.5-.1,12.5-.1h0c-1.7,1.4-3.2,2.9-4.4,4.5-1.2,1.6-2.5,3.7-4,6.3l-6.7,12.1v10.2c0,2.4.2,4.3.5,5.7.3,1.4,1,3.1,1.9,5h0c0,.1-15.6.1-15.6.1h0Z"/>
    </g>
    <g>
      <path className="st0"
            d="M58.9,37.8c.4-.7.6-1.3.7-1.8.1-.5.2-1.2.2-2.2v-8.1c0-.9,0-1.6-.2-2.2s-.3-1.1-.7-1.8h0s4.3,0,4.3,0h0c-.4.7-.6,1.3-.7,1.8-.1.5-.2,1.2-.2,2.2v8.1c0,.9,0,1.6.2,2.2.1.5.3,1.1.7,1.8h0s-4.3,0-4.3,0h0Z"/>
      <path className="st0"
            d="M75.6,26.1h-.2c-.7-1.5-1.5-2.6-2.2-3.2-.7-.6-1.5-1-2.4-1s-1.3.2-1.8.7c-.4.4-.6,1-.6,1.8s0,.8.2,1.1c.2.4.3.6.5.9s.5.5.9.7c.4.3.7.5,1,.6.3.1.6.3,1.2.6.6.3,1,.5,1.3.7s.7.4,1.2.7c.4.3.8.7,1,1,.2.3.5.7.6,1.2.2.5.3,1,.3,1.6,0,1.6-.5,2.7-1.6,3.5-1.1.8-2.5,1.2-4.1,1.2s-3-.3-4.5-.9l-.4-4.1h.2c.8,1.5,1.7,2.7,2.6,3.4s1.8,1.1,2.8,1.1,1.7-.3,2.2-.9c.5-.6.8-1.3.8-2.1s-.3-1.7-.9-2.3c-.6-.6-1.4-1.2-2.5-1.7-.5-.2-.8-.4-1-.5-.2-.1-.5-.3-.9-.5-.4-.2-.7-.4-.9-.6-.2-.2-.4-.4-.7-.6-.3-.3-.5-.5-.6-.8-.1-.3-.2-.6-.3-.9-.1-.4-.2-.7-.2-1.1,0-1.4.5-2.5,1.5-3.2,1-.7,2.2-1,3.6-1s2.4.2,3.6.6l.4,4Z"/>
      <path className="st0"
            d="M79.4,37.9h0c.4-.7.6-1.3.7-1.8.1-.5.2-1.2.2-2.1v-8.1c0-.9,0-1.6-.2-2.1-.1-.5-.4-1.1-.7-1.8h0s4.3,0,4.3,0h0c-.4.7-.6,1.3-.7,1.8-.1.5-.2,1.2-.2,2.2v11.8h3.3c1.5-.2,3.1-1.5,4.8-4.1h.2c0,0-1,4.4-1,4.4h-10.7Z"/>
      <path className="st0"
            d="M104.2,37.9h-11.3c.6-1.4.9-2.6.9-3.7v-8.7c0-.7,0-1.3-.2-1.8-.1-.5-.4-1.1-.7-1.9h0s10.2,0,10.2,0l.9,3.9h-.2c-.1,0-.5-.5-1.2-1.3-.6-.7-1.1-1.3-1.5-1.6s-.7-.5-1-.6l-3.7-.2v7.4h1.9c.2,0,.5,0,.8-.3.3-.2.5-.4.7-.5s.4-.5.7-.8c.3-.3.5-.6.6-.7h.2v5h-.2c0,0-.3-.3-.6-.6s-.5-.6-.7-.8c-.2-.2-.4-.4-.7-.5-.3-.2-.6-.3-.8-.3h-1.9v7.6l3.9-.2c.6,0,1.2-.3,1.8-.7.6-.5,1.1-.9,1.6-1.4s.9-1.1,1.4-1.8h.2c0,0-1,4.4-1,4.4Z"/>
      <path className="st0"
            d="M120.4,38.2c-2.1,0-4-.8-5.7-2.5-1.7-1.6-2.5-3.6-2.5-5.9s.8-4.2,2.5-5.9c1.7-1.7,3.6-2.5,5.7-2.5s2.7.4,4,1.1c1.3.7,2.3,1.8,3.1,3.1.8,1.3,1.2,2.7,1.2,4.2s-.4,2.9-1.2,4.2c-.8,1.3-1.8,2.3-3.1,3s-2.6,1.1-4,1.1ZM120.4,37.8c1.8,0,3.2-.7,4.1-2.2.9-1.4,1.4-3.4,1.4-5.8s-.5-4.3-1.4-5.8c-.9-1.5-2.3-2.2-4.1-2.2s-3.2.7-4.1,2.2c-.9,1.5-1.4,3.4-1.4,5.8s.5,4.3,1.4,5.7c.9,1.5,2.3,2.2,4.1,2.2Z"/>
      <path className="st0"
            d="M135.6,37.9h-4.3c.4-.7.6-1.3.7-1.8.1-.5.2-1.2.2-2.1v-8.1c0-.9,0-1.6-.2-2.1-.1-.5-.4-1.1-.7-1.8h0s10.8,0,10.8,0l.9,3.9h-.2c-.2-.2-.6-.6-1.2-1.3s-1.1-1.2-1.5-1.6-.8-.6-1.1-.6l-4.4-.2v7.3h2.3c.2,0,.5,0,.8-.3.3-.2.5-.4.7-.6.2-.2.4-.5.7-.8s.5-.6.6-.7h.2v5h-.2c0,0-.3-.3-.6-.6-.3-.4-.5-.6-.7-.8-.2-.2-.4-.4-.7-.5-.3-.2-.6-.3-.8-.3h-2.3v4.1c0,.9,0,1.6.2,2.2.1.5.3,1.1.7,1.8h0Z"/>
      <path className="st0"
            d="M149.7,37.9h0c.6-.8.9-1.5,1.1-2.1.2-.6.3-1.3.3-2.3v-8.6c0-.7-.2-1.3-.4-1.7s-.6-.9-1.2-1.4h0s4,0,4,0l5.7,12.5,5.4-12.5h3.9c-.6.6-1,1.1-1.3,1.6-.2.5-.3,1.2-.3,2.1v8.4c0,.9,0,1.6.2,2.2.1.5.3,1.1.7,1.8h0s-4.3,0-4.3,0h0c.4-.7.6-1.3.7-1.8.1-.5.2-1.2.2-2.1v-10.4l-6,13.9h-.5l-6.4-14v10.1c0,1,.2,1.8.4,2.3.2.6.6,1.2,1.3,2h0s-3.5,0-3.5,0Z"/>
      <path className="st0"
            d="M173,37.9h-3c.5-.6.9-1.1,1.1-1.6.3-.5.6-1.3,1-2.2l4.7-12.5h.4l4.8,12.7c.6,1.6,1.3,2.8,2,3.5h0s-4.4,0-4.4,0h0c.2-.3.3-.8.3-1.5,0-.7-.2-1.4-.5-2.2l-.9-2.4h-5.2l-.5,1.2c-.8,2-.7,3.6,0,4.8h0ZM176,25l-2.4,6.4h4.8l-2.4-6.4Z"/>
      <path className="st0"
            d="M187.9,24.1v9.4c0,1,.2,1.8.4,2.3.2.6.6,1.2,1.2,2h0s-3.4,0-3.4,0h0c.5-.8.9-1.5,1.1-2,.2-.6.3-1.4.3-2.3v-8.6c0-.7-.2-1.3-.4-1.7s-.7-.9-1.3-1.4h0s3.5,0,3.5,0l9.2,12.5v-8.1c0-1-.2-1.7-.3-2.3-.2-.6-.5-1.3-1.1-2h0s3.2,0,3.2,0h0c-.5.8-.9,1.5-1.1,2.1-.2.6-.3,1.3-.3,2.3v11.7h-.8c0,0-10.2-13.8-10.2-13.8Z"/>
    </g>
  </svg>
);