import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const FooterWrapper = styled.footer`
  display: grid;
  cursor: default;
  grid-template-columns: 1fr 1fr 2fr;
  padding: 32px 52px 0px 52px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  background: ${({ theme }) => theme.default.color.backgrounds.white};
  font-family:  ${({ theme }) => theme.default.fontFamily.primaryFont};
  border-top: ${({ theme }) => `1px solid ${theme.default.color.primary}`};
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
    padding: 16px;
  }
`;

export const InnerContactWrapper = styled.div`
  max-width: 70%;
  text-align: left;
  address {
    font-style: normal;
  }

  .contact-link {
    display: flex;
    align-items: center;
  }
`;

export const NewsletterWrapper = styled.div`
    display: flex;
    

    @media (max-width: ${defaultBreakpoints.medium}) {\
      margin: 20px 0 20px 0;
    }

    .newsletter-footer {
      margin: 0;
      padding: 0;
      background-color: ${({ theme }) => theme.default.color.backgrounds.white};

        .newsletter_card .grid_container, .checkbox {
          width: 85%;
        }
    
    }
`

export const ContactDetailsWrapper = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;

   @media (max-width: ${defaultBreakpoints.medium}) {
    flex-direction: row;
  }

`;

export const LogoWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  margin-bottom: 32px;
  #logo {
    max-width: 250px; 
    margin: 0;
  }

  @media (max-width: ${defaultBreakpoints.medium}) {
    margin: 0 auto;
    width: 100%;

     #logo {
      margin: 0 auto;
    }
  }
`;

export const FooterBottom = styled.div`
  background: ${({ theme }) => theme.default.color.backgrounds.white};
  display: flex;
  padding: 12px 52px 32px;
  font-family: ${({ theme }) => theme.default.fontFamily.secondaryFont};
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  `;

export const FooterBottomBorderContainer = styled.div`
  background: ${({ theme }) => theme.default.color.backgrounds.white};
`;

export const FooterBottomBorder = styled.div`
  width: calc(100% - 104px);
  border-top: ${({ theme }) => `1px solid ${theme.default.color.text.dark}`};
  margin: 0 auto; 
  background: ${({ theme }) => theme.default.color.backgrounds.white};
`;

export const ContactWrapper = styled.div`
  display: flex;

   @media (max-width: ${defaultBreakpoints.medium}) {
    padding-top: 26px;
  }
`;
export const SocialWrapper = styled.div`
  margin-right: auto;
  @media (max-width: ${defaultBreakpoints.medium}) {
    margin: 0;
  }
`;

export const SubHeading = styled.p`
  padding-bottom: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 12px; 
  text-transform: uppercase;
`

export const SocialLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  a {
      padding-right: 8px;
  }
`;

export const FooterMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px;

  .footer-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      margin: 4px 0;

      &:hover {
          text-decoration: underline;
      }
    }
  }

   @media (max-width: ${defaultBreakpoints.medium}) {
    padding: 16px 0 0 0;
  }
`;

export const LinksWrapper = styled.div`
  display: inline-flex;
  padding-bottom: 8px;
   svg {
      margin-right: 10px;
   }
`;

export const LegalText = styled.p`
  font-size: 12px;
`;
